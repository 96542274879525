import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ArrowRightIcon,
  BellIcon,
  XMarkIcon,
  PlayIcon,
} from "@heroicons/react/24/solid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import CsrSlider from "../components/sliders/csr-slider"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import HeroSlider from "../components/sliders/hero-slider"
import CavingSlider from "../components/sliders/caving-slider"
import AudioPlayer from "../components/audioplayer"
import ReactAudioPlayer from "react-audio-player"

const IndexPage = ({ data }) => {
  const images = {
    mihinThalaya: convertToBgImage(getImage(data.mihinThalaya)),
    mihinThalaya1: getImage(data.mihinThalaya),
    fb: getImage(data.fb),
  }

  const social = [
    {
      icon: images.fb,
    },
    {
      icon: images.fb,
    },
    {
      icon: images.fb,
    },
    {
      icon: images.fb,
    },
    {
      icon: images.fb,
    },
  ]

  return (
    <>
      <Layout>
        <div>
          <div className="">
            <BackgroundImage {...images.mihinThalaya} className=" pt-60 w-full">
              <div className="container mx-auto sm:px-20 px-10 h-full pb-20">
                <div className="flex h-full items-end">
                  <div>
                    <div className="sm:text-6xl text-4xl font-bold mb-4  text-white max-w-lg">
                      ආරාධනා ගල
                    </div>
                    <div className="  text-white text-lg">
                      මෙම ස්ථානය පිලිබදව සිංහල බසින් පහතින් ශ්‍රවනය කරන්න
                    </div>
                    <div className="mt-4">
                      <ReactAudioPlayer
                        src="https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3"
                        autoPlay
                        controls
                      />
                    </div>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
        {/* bottom half */}
        <div className="container mx-auto md:px-20 px-10  pt-36 pb-20  ">
          <div className="flex lg:flex-row flex-col gap-7 ">
            <div className="w-full lg:w-3/4">
              <div className="pb-10 ">
                <div className=" text-[#272621]  pt-2">
                  <div>
                    ඔබ ඉදිරියේ ඇත්තේ මිහින්තලා පරිශ්‍රය තුල දක්නට ලැබෙන සුවිශේෂම
                    සංකේතය වන මිස්සක පවුවයි. මෙය අපි අරාධනා ගල ලෙසද හඳුන්වනවා.
                  </div>

                  <div className="pt-5">
                    මිහිඳු මහ රහතන් වහන්සේ උන්වහන්සේගේ දූත පිරිස වන ඉට්ඨිය
                    ,උත්තිය ,සම්බල, බද්දසාල යන රහතන් වහන්සේලාත්, ෂඩ් අභිඥාලාභී
                    සුමන සාමනේරයන්ද, භණ්ඩුක නම් අනාගාමී උපාසකවරයා සමග දඹදිව සිට
                    අහසින් මෙහි වැඩමකරවා බිමට වැඩම කල ස්ථානය ලෙස මෙය සඳහන්
                    වෙනවා. නමුත් මෙම පවුවට අරාධනා ගල ලෙස නම් ලැබී තිබෙන්නේ තවත්
                    හේතුවක් නිසාවෙනුයි.
                  </div>
                </div>
              </div>

              <div className="pb-8 flex md:flex-row flex-col gap-4">
                <GatsbyImage
                  image={images.mihinThalaya1}
                  className="w-full rounded-3xl"
                />
                <GatsbyImage
                  image={images.mihinThalaya1}
                  className="w-full rounded-3xl"
                />
              </div>

              <div className=" text-[#272621]  pt-2">
                රජතුමා ඇතුළු පිරිසට “චුල්ල හත්ථි පදෝපම” සූත්‍රය දේශනා කිරීමට
                ප්‍රථම මිහිඳු මහ රහතන් වහන්සේගේ මඟ පෙන්වීම අනුව සුමන සාමණේරයන්
                වහන්සේ විසින් දෙවියන්ට ධර්මය ශ්‍රවණය සඳහා මේ පර්වතයේ සිට ආරාධනා
                කරනු ලැබූ හෙයින් එය ‘ආරාධනා ගල’ ලෙස නම් කල බව මූලාශ්‍ර වල සඳහන්
                වෙනවා.
              </div>

              <div className="border-y-2   border-black		 py-6  	mt-20">
                <div className="flex gap-6  items-center ">
                  <div className="mt-2 sm:block hidden">
                    <StaticImage
                      src="../images/pages/sadaham-sathkara/Tea/Vector.png"
                      className=""
                      alt="share"
                    />
                  </div>
                  <div className="">Share: </div>
                  <div>
                    <div className="flex gap-5">
                      {social.map((item, index) => {
                        return (
                          <Link to="/">
                            <GatsbyImage
                              image={images.fb}
                              className="sm:w-10 w-5 cursor-pointer"
                            />
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-14">
                <div className="flex items-center justify-between">
                  <Link to="/drinking-water-project–kataragama" className="">
                    <div className="flex items-center gap-3">
                      <div className="w-16 h-16 rounded-full bg-yellow flex items-center justify-center cursor-pointer">
                        <PlayIcon className="text-white w-8 rotate-180" />
                      </div>
                      <div className="flex flex-col">
                        <div className="uppercase font-light sm:block hidden">
                          පෙර ලිපිය
                        </div>
                        <div className=" font-semibold xl:block hidden">
                          සන්නිපාත ශාලාව
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="/celebrating-avurudu-with-the-farming-community"
                    className=""
                  >
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col text-right">
                        <div className="uppercase font-light sm:block hidden">
                          ඊලග ලිපිය
                        </div>
                        <div className=" font-semibold xl:block hidden">
                          ගිරිභණ්ඩ සෑය
                        </div>
                      </div>
                      <div className="w-16 h-16 rounded-full bg-yellow flex items-center justify-center cursor-pointer">
                        <PlayIcon className="text-white w-8" />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomeImages {
    mihinThalaya: file(
      relativePath: { eq: "pages/sadaham-sathkara/mihinthalaya.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    fb: file(relativePath: { eq: "pages/sadaham-sathkara/Tea/fb.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
